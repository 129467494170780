import React, { useState } from "react"
import { Text } from "@thedgbrt/logology-ui-components"
import ForgotPasswordForm from "./ForgotPasswordForm"
import { getUriParameterByName } from "../../../helpers"

const ForgotPassword = ({ location }) => {
  const [status, setStatus] = useState("forgotPassword")

  const handleSubmitSuccess = async email => {
    setStatus("verification")
  }

  const email = getUriParameterByName("email", location)

  if (status === "forgotPassword")
    return (
      <ForgotPasswordForm onSuccess={handleSubmitSuccess} initEmail={email} />
    )

  return (
    <Text standalone>
      Please check your email. We've sent you a password reset link.
    </Text>
  )
}

export default ForgotPassword
