import React from "react"
import { GridBox, GridContainer, Title } from "@thedgbrt/logology-ui-components"
import SEO from "../components/seo"
import ForgotPassword from "../components/auth/ForgotPassword"

const ForgotPasswordPage = props => (
  <>
    <SEO title="Forgot password" />

    <GridBox type={5} colSpans={[[3, 9], [3, 9], [5, 10]]} withBgContainer>
      <Title standalone level={2}>
        Get a new password
      </Title>
    </GridBox>

    <GridContainer
      rowsDesktop={["auto"]}
      rowsMobile={["auto"]}
      rowsTablet={["auto"]}
      type={5}
    >
      <GridBox
        type={5}
        colSpans={[[3, 9], [3, 9], [5, 10]]}
        withBorder
        hideBorderRightMobile
      >
        <ForgotPassword location={props.location.href} />
      </GridBox>
    </GridContainer>
  </>
)

export default ForgotPasswordPage
