import React from "react"
import { Link } from "gatsby"
import { Text } from "@thedgbrt/logology-ui-components"

const FormError = ({ error, withMargin }) => (
  <>
    {error.exists && (
      <Text standalone style={withMargin ? {} : { marginTop: 0 }}>
        <b style={{ color: "red" }}>
          {error.message}{" "}
          {error.showLinkToSignIn && (
            <Link
              to={`/myaccount?email=${encodeURIComponent(error.email)}`}
              className="btn btn-sm btn-outline-primary"
            >
              Sign in
            </Link>
          )}
        </b>
      </Text>
    )}
  </>
)

export default FormError
