import React from "react"
import {
  makeRem,
  Form,
  FormInput,
  FormSubmit,
  Spinner,
} from "@thedgbrt/logology-ui-components"
import { FormError } from "../../common/Form"
import useForgotPasswordForm from "./useForgotPasswordForm"

const ForgotPasswordForm = ({ initEmail, onSuccess }) => {
  let formRef = React.createRef()
  let inputEmailRef = React.createRef()

  const {
    error,
    inputs,
    inputErrors,
    handleInputChange,
    handleSubmit,
    loading,
  } = useForgotPasswordForm({ initEmail, successCallback: onSuccess })

  return (
    <>
      {loading ? (
        <Spinner align="left" standalone />
      ) : (
        <>
          <form ref={formRef} style={{ paddingTop: makeRem(12) }}>
            <FormError error={error} />
            <Form standalone>
              <FormInput
                placeholder="My email address&hellip;"
                type="email"
                name="email"
                error={inputErrors.email}
                onChange={e => handleInputChange(e, inputEmailRef)}
                value={inputs.email}
                ref={inputEmailRef}
                required
              />
              <FormSubmit
                text="Reset password"
                id="forget-password-submit"
                onClick={e => handleSubmit(e, [inputEmailRef])}
              />
            </Form>
          </form>
        </>
      )}
    </>
  )
}

export default ForgotPasswordForm
