import { useState } from "react"
import Auth from "@aws-amplify/auth"
import { validateInput } from "../../../helpers/validation"

const useForgotPasswordForm = ({ initEmail, successCallback }) => {
  const [error, setError] = useState({
    email: "",
    exists: false,
    message: "",
    showLinkToSignIn: false,
  })

  const [hasValidatedOnce, setHasValidatedOnce] = useState(false)
  const [inputs, setInputs] = useState({ email: initEmail, password: "" })
  const [inputErrors, setInputErrors] = useState({ email: "", password: "" })
  const [loading, setLoading] = useState(false)

  const handleInputChange = (event, ref) => {
    event.persist()
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
    // if we already validated the form once (after first submit try),
    // update validation results in real time
    if (hasValidatedOnce) validateInput(ref, setInputErrors)
  }

  const handleError = ({ code, name, message }, email) => {
    // let displayMessage
    const showLinkToSignIn = code === "UsernameExistsException"
    setError({ exists: true, message: message, showLinkToSignIn, email })
  }

  const handleSubmit = event => {
    if (event) {
      event.preventDefault()
      setHasValidatedOnce(true)
    }

    // try to sign in
    setLoading(true)

    // force lowercase to fix aws email case issue
    const username = inputs.email.toLowerCase().trim()

    Auth.forgotPassword(username)
      .then(data => {
        setLoading(false)
        successCallback(username)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
        handleError(err, username)
      })
  }
  return {
    error,
    handleSubmit,
    handleInputChange,
    inputs,
    inputErrors,
    loading,
  }
}

export default useForgotPasswordForm
